<template >
  <div class="mt-5">
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{model.id}} - {{ $t("message.deal")}}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-container>
              <el-header><b>{{ $t('message.deal') }}: {{ model.name }} </b></el-header>
              <el-main>
                <el-row :gutter="20">
                  <el-col :span="6">
                    <p> {{$t('message.user')}}: {{ model.user ? model.user.name : '' }}</p>
                  </el-col>
                  <el-col :span="6">
                     <p> {{$t('message.filial')}}: {{ model.filial ? model.filial.name : '' }}</p>
                  </el-col>
                  <el-col :span="6">
                    <p>  {{$t('message.date')}}: {{ model.begin_date }} <b>/</b> {{ model.end_date }}</p>
                  </el-col>
                  <el-col :span="6">
                     <p> {{$t('message.money_amount')}}: {{ model.money_amount }} {{ model.currency ? model.currency.code: '' }}</p>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="mt-4">
                  <el-col :span="6">
                    <p> {{$t('message.company')}}: {{ model.company ? model.company.name : '' }}</p>
                  </el-col>
                  <el-col :span="6">
                    <p> {{$t('message.client')}}: {{ model.client ? model.client.full_name : '' }}</p>
                  </el-col>
                  <el-col :span="6">
                     <p> {{$t('message.phone')}}: {{ model.phone }}</p>
                  </el-col>
                  <el-col :span="6">
                     <p> {{$t('message.email')}}: {{ model.email }}</p>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="mt-4">
                  <el-col :span="6">
                   <p>  {{$t('message.deal_type')}}: {{ model.deal_type ? model.deal_type.name : '' }} </p>
                  </el-col>
                  <el-col :span="6">
                    <p> {{$t('message.source')}}: {{ model.source ? model.source.name : '' }} </p>
                  </el-col>
                  <el-col :span="6">
                    <p>  {{$t('message.exhibition')}}: {{ model.exhibition ? model.exhibition.name : '' }} </p>
                  </el-col>
                  <el-col :span="6">
                     <p> {{$t('message.shipmentTerm')}}: {{ model.shipmentTerm ? model.shipmentTerm.name : '' }} </p>
                  </el-col>
                </el-row>
              </el-main>
            </el-container>
            
        </div>
      </div>
      <!-- end app-modal__body -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-container>
              <el-header><b>{{ $t('message.calls') }} </b></el-header>
              <el-main v-loading="loadingCallHistory">
                <template v-if="callHistoryData.length > 0">
                  <el-table
                    :data="callHistoryData"
                    border
                    stripe
                    style="width: 100%">
                    <el-table-column
                      prop="cnam"
                      :label="callHisColumns.caller_name.title">
                        <template slot-scope="scope"> 
                          {{ checkContact(scope.row) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                      prop="cnum"
                      :label="callHisColumns.caller.title">
                        <template slot-scope="scope"> 
                          <span style="display: block;">
                              {{ scope.row.cnum }}
                          </span> 
                          <span>
                              {{ scope.row.cnum !== scope.row.src ? '( ' + scope.row.src + ' )' : '' }}
                          </span>  
                        </template>
                    </el-table-column>
                    <el-table-column
                      prop="dst"
                      :label="callHisColumns.target_name.title">
                        <template slot-scope="scope"> 
                          <span v-html="removePrefixFromNumber(scope.row, true)"></span> 
                        </template>
                    </el-table-column>
                    <el-table-column
                      prop="dst"
                      :label="callHisColumns.called_to.title">
                        <template slot-scope="scope"> 
                          <span v-html="removePrefixFromNumber(scope.row, false)"></span> 
                        </template>
                    </el-table-column>
                    <el-table-column
                      prop="billsec"
                      :label="callHisColumns.billsec.title">
                        <template slot-scope="scope"> 
                          {{ calculateCallDuration(scope.row.billsec) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                      prop="disposition"
                      :label="callHisColumns.callStatus.title">
                         <template slot-scope="scope"> 
                          <div v-if="scope.row.disposition === 'ANSWERED'" class="coo1">{{ $t('message.' + scope.row.disposition) }}</div>
                          <div v-if="scope.row.disposition === 'NO ANSWER' || scope.row.disposition === 'BUSY'" class="coo2">{{ $t('message.' + scope.row.disposition) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                      prop="calldate"
                      :label="callHisColumns.calldate.title">
                    </el-table-column>
                    <el-table-column
                      prop="calldate"
                      width="320"
                      :label="callHisColumns.recordingfile.title">
                      <template slot-scope="scope">
                          <div v-if="scope.row.recordingfile">
                            <audio id="audioFromExternal" controls :src="listenToTheAudio(scope.row)"></audio>
                          </div>
                          <span v-if="!scope.row.recordingfile">  {{$t("message.no_audio_file")}} </span>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-main>
            </el-container>
            <el-container>
              <el-header>  
                <b>{{ $t('message.questions') }}</b> 
              </el-header>
              <el-main>
                <el-row :gutter="20" v-for="(answer, index) in this.answerList" :key="'quest'+index" class="mt-4">
                  <el-col :span="8">
                    <b>{{ $t('message.n') }} {{index+1}}:</b> {{ showName(answer.happy_call_question_id) }}
                  </el-col>
                  <el-col :span="4">
                    <el-rate v-model="answer.rating" :disabled="finishedDeals"></el-rate>
                  </el-col>
                  <el-col :span="12">
                    <el-input
                      type="textarea"
                      autosize
                      :disabled="finishedDeals"
                      :placeholder="$t('message.comment')"
                      v-model="answer.comment">
                    </el-input>
                  </el-col>
                  
                </el-row>
              </el-main>
            </el-container>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import callHistory from "@/utils/mixins/views/callHistory";

export default {
    mixins: [form, drawer, show, callHistory],
    props: ['finishedDeals'],
    name: "deal",
    data() {
        return {
          answerList: [],
          loadingCallHistory: false,
          callHistoryData: [],

        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "deals/rules",
            model: "deals/model",
            columns: "deals/columns",
            callHisColumns: "callHistories/columns",
            questionInventory: "happyCallQuestions/inventory",
            callList: "callHistories/list",
            authUser: "auth/user",
            phoneContacts: "phoneContacts/list",
            serverSipAccountList: "sipAccounts/serverSipAccountList",
        }),
    },
    methods: {
        ...mapActions({
            storeAnswers: "happyCallAnswers/store",
            show: "deals/show",
            updateQuestionInventory: "happyCallQuestions/inventory",
            updateCallList: "callHistories/index",
            updateContactList: "phoneContacts/index",
        }),
       
        async afterOpen() {        
          await this.updateQuestionInv();
          await this.fetchData();
        },
        updateQuestionInv(){
          if(this.questionInventory.length === 0) this.updateQuestionInventory();
        },
        fetchData() {
          if (!this.loadingData) {  
              this.loadingData=true;
              this.show(this.selectedItem.id).then(res => {
                  if(this.model.happyCalls.length > 0){
                    this.loadingCallHistory = true;     
                    this.updateCallList({uniqueIds: this.model.happyCalls}).then(res => {
                      this.callHistoryData = JSON.parse(JSON.stringify(res.data.data))
                      this.loadingCallHistory = false;
                    }).catch(error => {
                      this.loadingCallHistory = false;
                    });
                  }
                  
                  this.answerList = [];
                  if(this.model.answers.length > 0){
                    this.answerList = JSON.parse(JSON.stringify(this.model.answers));
                    if(this.answerList.length < this.questionInventory.length && !this.finishedDeals){
                      let ans = this.answerList.map(el => el.happy_call_question_id);
                      let newQuestions = this.questionInventory.filter(x => !ans.includes(x.id));
                      this.updateAnswerList(newQuestions);
                    }
                  }else{
                    this.updateAnswerList(this.questionInventory);
                  }  
                  this.loadingData = false              
              }).catch(err => {
                  this.loadingData = false
              });
          }
        },
        updateAnswerList(list){
          list.forEach(element => {
            let answer = {
              id: null,
              deal_id: this.selectedItem.id,
              happy_call_question_id: element.id,
              rating: null,
              comment: ''
            }
            this.answerList.push(answer);
          });
        },
        showName(question_id){
          let quest = this.questionInventory.find(el => el.id === question_id);
          if(quest && quest.name) return quest.name;
          return '';
        },
        submit(close = true) {
          this.loadingButton = true;
          this.storeAnswers(this.answerList)
              .then((res) => {
                  this.loadingButton = false;
                  this.$alert(res);
                  this.parent().listChanged();
                  if (res.status == 200) this.close();
              })
              .catch((err) => {
                  this.loadingButton = false;
                  this.$alert(err);
              });

        },
        afterLeave(){
          this.answerList = [];
          this.callHistoryData = [];
        }
    },
};
</script>
<style>
  .el-header, .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }
</style>