import _ from 'lodash';
export default {
  props:{},   
  data() {
    return {
    }
  },  
  methods:{
    calculateCallDuration(duration){
        let minutes = Math.floor(duration/60)
        let seconds = duration%60

        if(minutes < 10){
            minutes = '0'+minutes; 
        }
        if(seconds < 10){
            seconds = '0'+seconds; 
        }
        let time = minutes + ' : ' + seconds;
        return time;

    },
    checkContact(call){
        let caller_name = '';
        if(caller_name = this.phoneContacts.find(item => item.phone_number.includes(call.cnam))){
            caller_name = caller_name.name;
        }else{
            if(call.cnam === call.cnum){
                caller_name = ''

            }else{
                caller_name = call.cnam
            }
        }
        return caller_name;
    },
    removePrefixFromNumber(call, show_name) {
        let number = '';
            let name = '';
            if (call.dst.length > 5 && call.cnum !== call.src && this.outboundNumbers && this.outboundNumbers.find(phone => phone.phone_number.includes(call.src))) {
                this.outboundNumbers.forEach(phone => {
                    if (phone.phone_number.includes(call.src)) {
                        number = JSON.parse(JSON.stringify(call.dst));
                        if (phone.prefix == number.slice(0, phone.prefix.length)) {
                            number = number.slice(phone.prefix.length);
                        }
                    }
                });
            } else if (call.dst.length <= 5) {
                number = JSON.parse(JSON.stringify(call.dst));
                let cont_t = '';
                if (this.sipAccountList) {
                    cont_t = this.sipAccountList.find(item => item.username === call.dst);
                }
                if (cont_t && cont_t !== undefined && cont_t !== null) {
                    name = cont_t ? JSON.parse(JSON.stringify(cont_t.displayname)) : '';
                }
            }else if(call.dst.length > 5 && call.cnum !== call.src){
                number = call.dst.substr(call.dst.length - 9);
            }
            if (show_name) {
                return name;
            }
            return number;
    },
    listenToTheAudio(call){
        let url = '';
        let month = new Date(call.calldate).getMonth() + 1;
        if(month < 10){
            month = '0' + month;
        }
        
        let day = new Date(call.calldate).getDate();
        if(day < 10){
            day = '0' + day;
        }

        url += new Date(call.calldate).getFullYear() + '/' + month + '/' + day;
        url += '/'+call.recordingfile;
        url = process.env.VUE_APP_CALL_HISTORY_URL + '/' + url;
        return url
    }
  }

}