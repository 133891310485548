<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.deals") }} </div>
              <div class="block-sarche">
                <div class="header__search">

                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>

                </div>

              </div>
              <el-button-group class="ml-2">
                <el-button v-loading="loadingData" type="primary" size="small" @click="finishedDeals = !finishedDeals">{{ activeName }}</el-button>
              </el-button-group>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = false"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th>
                    {{ $t('message.rating') }}
                </th>

                <th v-if="columns.user.show">
                    {{ columns.user.title }}
                </th>

                <th v-if="columns.filial.show">
                    {{ columns.filial.title }}
                </th>

                <th v-if="columns.name.show">
                    {{ columns.name.title }}
                </th>

                <th v-if="columns.client.show">
                    {{ columns.client.title }}
                </th>

                <th v-if="columns.money_amount.show">
                    {{ columns.money_amount.title }}
                </th>

                <th v-if="columns.currency.show">
                    {{ columns.currency.title }}
                </th>

                <th v-if="columns.phone.show">
                    {{ columns.phone.title }}
                </th>

                <th v-if="columns.email.show">
                    {{ columns.email.title }}
                </th>

                <th v-if="columns.begin_date.show">
                    {{ columns.begin_date.title }}
                </th>

                <th v-if="columns.end_date.show">
                    {{ columns.end_date.title }}
                </th>

                <th v-if="columns.dealStage.show">
                    {{ columns.dealStage.title }}
                </th>

                <th style="width:50px   ">
                    {{ $t('message.call') }}
                </th>

                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
                <th v-if="columns.id.show">
                    <el-input
                        clearable
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.id"
                        :placeholder="columns.id.title"
                        class="id_input"
                    ></el-input>
                </th>

                <th>
                    <crm-input
                        :placeholder="$t('message.rating')"
                        disabled
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.user_id"
                    ></crm-input>
                </th>

                <th v-if="columns.user.show">
                    <crm-input
                        :placeholder="columns.user.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.user_id"
                    ></crm-input>
                </th>

                <th v-if="columns.filial.show">
                    <crm-input
                        :placeholder="columns.filial.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.filial_id"
                    ></crm-input>
                </th>

                <th v-if="columns.name.show">
                    <crm-input
                        :placeholder="columns.name.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.name"
                    ></crm-input>
                </th>

                <th v-if="columns.client.show">
                    <crm-input
                        :placeholder="columns.client.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.client_id"
                    ></crm-input>
                </th>

                <th v-if="columns.money_amount.show">
                    <crm-input
                        :placeholder="columns.money_amount.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.money_amount"
                    ></crm-input>
                </th>

                <th v-if="columns.currency.show">
                    <crm-input
                        :placeholder="columns.currency.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.currency_id"
                    ></crm-input>
                </th>

                <th v-if="columns.phone.show">
                    <crm-input
                        :placeholder="columns.phone.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.phone"
                    ></crm-input>
                </th>

                <th v-if="columns.email.show">
                    <crm-input
                        :placeholder="columns.email.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.email"
                    ></crm-input>
                </th>

                <th v-if="columns.begin_date.show">
                    <crm-input
                        :placeholder="columns.begin_date.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.begin_date"
                    ></crm-input>
                </th>

                <th v-if="columns.end_date.show">
                    <crm-input
                        :placeholder="columns.end_date.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.end_date"
                    ></crm-input>
                </th>

                <th v-if="columns.dealStage.show">
                    <crm-input
                        :placeholder="columns.dealStage.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.deal_stage_id"
                    ></crm-input>
                </th>
                <th >
                    <crm-input
                        :placeholder="$t('message.call')"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        disabled
                    ></crm-input>
                </th>

                <th
                    class="settinW"
                    v-if="columns.settings.show"
                ></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="deal in list" :key="deal.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ deal.id }}
                </td>

                <td v-if="columns.id.show">
                    <el-rate class="min-w-100" v-model="deal.rateAverage" disabled></el-rate>
                </td>

                <td v-if="columns.user.show">
                    {{ deal.user ? deal.user.name : '' }}
                </td>

                <td v-if="columns.filial.show">
                    {{ deal.filial ? deal.filial.name : '' }}
                </td>

                <td v-if="columns.name.show">
                    <a href="#" style="color:rgb(69, 86, 236)" class="colorHover" @click="edit(deal)">{{ deal.name }}</a>
                </td>

                <td v-if="columns.client.show">
                    {{ deal.client ? deal.client.full_name : '' }}
                </td>

                <td v-if="columns.money_amount.show">
                    {{ deal.money_amount | formatNumber(1) }}
                </td>

                <td v-if="columns.currency.show">
                    {{ deal.currency ? deal.currency.code : '' }}
                </td>

                <td v-if="columns.phone.show">
                    {{ deal.phone }}
                </td>

                <td v-if="columns.email.show">
                    {{ deal.email }}
                </td>

                <td v-if="columns.begin_date.show">
                    {{ deal.begin_date }}
                </td>

                <td v-if="columns.end_date.show">
                    {{ deal.end_date }}
                </td>

                <td v-if="columns.dealStage.show">
                    {{ deal.deal_stage ? deal.deal_stage.name : '' }}
                </td>

                <td>
                    <el-button :disabled="!phoneStatus || deal.phone_country_code !== 'UZ'" @click="callToClient(deal)" type="primary" icon="el-icon-phone-outline" circle></el-button>
                </td>

                <td v-if="columns.settings.show" class="settings-td">
                    <crm-settings
                        :name="$options.name"
                        :model="deal"
                        :actions="actions"
                        :permissionShow="'deals.update'"
                        @edit="edit"
                    ></crm-settings>
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="80%"
                class="body_scroll_80"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    :finishedDeals="finishedDeals"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions } from "vuex";
import { i18n } from '@/utils/i18n';

export default {
    name: "deals",
    mixins: [list],
    components: {
        Pagination,
        CrmUpdate,
    },

    data() {
        return {
            finishedDeals: false,
            activeName: i18n.t('message.active'),
            phoneStatus: false
        };
    },
    watch: {
        finishedDeals: {
            handler: async function(newVal, oldVal) {
                if(newVal){
                    this.activeName = this.$t('message.active');
                }else{
                    this.activeName = this.$t('message.archived');
                }
                await this.updatePagination({ key: "page", value: 1 });
                this.debouncedFetchData();
            },
            deep: true,
            immediate: true
        },
        $phoneUA: {
            handler: async function(newVal, oldVal) {
               this.phoneStatus = _.isEmpty(newVal) ? false : newVal.isRegistered();
            },
            deep: true,
            immediate: true
        },
    },

    computed: {
        ...mapGetters({
            list: "deals/list",
            columns: "deals/columns",
            pagination: "deals/pagination",
            filter: "deals/filter",
            sort: "deals/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "deals/index",
            setPagination: "deals/setPagination",
            updateSort: "deals/updateSort",
            updateFilter: "deals/updateFilter",
            updateColumn: "deals/updateColumn",
            updatePagination: "deals/updatePagination",
            show: "deals/show",
            empty: "deals/empty",
            refreshData: "deals/refreshData",
        }),

        fetchData() {
            const query = {...this.filter, ...this.pagination, ...this.sort, finish_date: true, finished_happy_call_deals: this.finishedDeals};
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
        callToClient(deal){
            this.edit(deal);
            this.$emit('getPhoneToCall', {
                phone: deal.phone,
                src: '',
                phone_country_code: deal.phone_country_code,
                call: true,
                callable: {
                    callable_type: 'deals',
                    callable_id: deal.id,
                    calling_type: 'happy_call',
                    call_unique_id: null,
                }
            })
        }

    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('deals/EMPTY_LIST');
        next()
    },
};
</script>
<style>
table .cursor-pointer:hover .colorHover{
    color: #fff !important;
}
</style>
